<template>
  <modal title="Update Contact" :active="active" @close="close">
    <diyobo-input
      type="text"
      label="First Name"
      placeholder="John"
      required
      :val="formValues.firstName"
      :error="formErrors.firstName"
      v-model="formValues.firstName"
      @keyup="validateAt('firstName')"
    />
    <diyobo-input
      type="text"
      label="Last Name"
      placeholder="Doe"
      required
      :val="formValues.lastName"
      :error="formErrors.lastName"
      v-model="formValues.lastName"
      @keyup="validateAt('lastName')"
    />
    <diyobo-input
      type="text"
      label="Email"
      placeholder="example@email.com"
      required
      :val="formValues.email"
      :error="formErrors.email"
      v-model="formValues.email"
      @keyup="validateAt('email')"
    />
    <diyobo-input
      type="text"
      label="Phone"
      placeholder="Phone Number"
      :val="phoneOutput"
      :error="formErrors.phone"
      v-model="phoneOutput"
      @keyup="validateAt('phone')"
    />
    <template #footer>
      <diyobo-button type="secondary" txt="Cancel" @click="close" />
      <diyobo-button type="primary" txt="Save" @click="submit" />
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import DiyoboInput from "@/components/DiyoboInput.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";
import * as yup from "yup";
import { formatPhoneNumber } from "@/helpers/input-formats";

const VALIDATION_SCHEMA = yup.object({
  firstName: yup.string().required("First Name is required."),
  lastName: yup.string().required("Last Name Amount is required."),
  email: yup
    .string()
    .email()
    .required("Email is required."),
  phone: yup.string()
});

export default {
  name: "update-contact-modal",
  components: {
    Modal,
    DiyoboInput,
    DiyoboButton
  },
  data() {
    return {
      active: false,
      contact: null,
      error: null,
      formValues: {
        firstName: "",
        lastName: "",
        email: "",
        phone: ""
      },
      formErrors: {
        firstName: "",
        lastName: "",
        email: "",
        phone: ""
      }
    };
  },
  computed: {
    phoneOutput: {
      get() {
        return this.formValues.phone;
      },
      set(value) {
        this.formValues.phone = formatPhoneNumber(value);
      }
    }
  },
  methods: {
    validateAt(field) {
      VALIDATION_SCHEMA.validateAt(field, this.formValues)
        .then(() => {
          this.formErrors[field] = "";
        })
        .catch(err => {
          this.formErrors[field] = err.message;
        });
    },
    open(args = {}) {
      this.onConfirm = args.onConfirm;
      this.contact = args.contact;
      this.error = args.error;

      this.formValues.firstName = this.contact.first_name;
      this.formValues.lastName = this.contact.last_name;
      this.formValues.email = this.contact.email;
      this.formValues.phone = this.contact.phone;

      document.body.style.overflow = "hidden";

      setTimeout(() => {
        this.active = true;
      }, 250);
    },
    close() {
      this.active = false;

      setTimeout(() => {
        this.contact = null;

        document.body.style.position = "relative";
      }, 500);
    },
    okay() {
      if (this.onConfirm) this.onConfirm();
      this.close();
    },
    async submit() {
      try {
        await VALIDATION_SCHEMA.validate(this.formValues, {
          abortEarly: false
        });
      } catch (error) {
        error.inner.forEach(err => {
          this.formErrors[err.path] = err.message;
        });

        return;
      }

      this.$loader.start();
      this.close();

      const contact = {
        firstName: this.formValues.firstName,
        lastName: this.formValues.lastName,
        email: this.formValues.email,
        phone: this.formValues.phone
      };

      this.$axios
        .post("/emails/contacts/update", {
          errorID: this.error.id,
          contactID: this.contact.id,
          contact
        })
        .then(response => {
          this.$emit("submit", this.error, response.data.contact);
          this.$loader.stop();
        });
    }
  }
};
</script>

<style lang="less" scoped>
.modal &::v-deep {
  .modal-footer {
    button {
      margin-left: 16px;
    }
  }
}
</style>
