<template>
  <div>
    <alert-modal ref="alert"/>
    <processing-modal :active="processing" v-on:cancel="processing = false"/>

    <Card>
      <section class="main-content">
        <div class="row">
          <div class="col half">
            <FormulateInput
              type="select"
              label="Comp or Will Call?"
              :options="{
                ComplimentaryTickets: 'Complimentary Tickets',
                WillCall: 'Will Call'
              }"
              value="ComplimentaryTickets"
              @input="onClickCompType"
              validation="required"
            />
          </div>
          <div class="col half">
            <FormulateInput
              type="select"
              label="Issuing To"
              :options="{
                individual: 'Individual',
                group: 'Group/Company'
              }"
              value="individual"
              @input="issuingTo"
              validation="required"
            />
          </div>
          <div class="col full csv-importer-wrapper">
            <CSVImporter
              v-model="compsUploaded"
              :columns="column"
              :type="compType"
              :issuedTo="issuedTo"
            />
            <!-- <template>
            <div class="splitter row">or</div>
          </template> -->
          </div>
          <!-- <div class="comps-buttons-wrapper">
          <diyobo-button
            txt="Back To My Events"
            type="primary-dark"
            v-on:click="goBack"
          />
          <diyobo-button
            v-on:click="validateForm()"
            id="submit-button"
            txt="Submit"
            type="primary"
          />
        </div> -->
        </div>
        <!--        <div class="imports-items" v-if="compsUploaded.length">-->
        <!--          <table class="table" v-if="compsUploaded.length">-->
        <!--            <tr class="table-labels">-->
        <!--              <th>First name</th>-->
        <!--              <th>Last name</th>-->
        <!--              <th>Email</th>-->
        <!--              <th>Phone</th>-->
        <!--              <th>Tier</th>-->
        <!--              <th>Quantity</th>-->
        <!--              <th>Seats</th>-->
        <!--              <th class="for-btn"></th>-->
        <!--            </tr>-->
        <!--            <tr-->
        <!--                class="table-info"-->
        <!--                v-for="(item, index) in compsUploaded"-->
        <!--                :key="index"-->
        <!--            >-->
        <!--              <td>{{ item.firstName}}</td>-->
        <!--              <td>{{ item.lastName}}</td>-->
        <!--              <td>{{ item.email}}</td>-->
        <!--              <td>{{ item.phone}}</td>-->
        <!--              <td>{{ item.tier}}</td>-->
        <!--              <td>{{ item.quantity}}</td>-->
        <!--              <td>{{ item.seats}}</td>-->
        <!--              <td>-->
        <!--                <button-->
        <!--                  class="reserved-btn btn"-->
        <!--                  name="Set seats"-->
        <!--                  v-on:click="openReservedMap"-->
        <!--              />Set seats</button>-->
        <!--              </td>-->
        <!--            </tr>-->
        <!--          </table>-->
        <!--        </div>-->
        <p>
          Click to
          <a @click="downloadExamopleCSV" style="cursor: pointer">Download</a>
          CSV template.
        </p>
        <div class="row">
          <div class="col half redirect-button">
            <FormulateInput
              class="redirect-button"
              type="button"
              name="Back to My Events"
            />
          </div>
          <div class="col half">
            <FormulateInput
              type="button"
              :disabled="processing"
              :name="processing ? 'Submitting...' : 'Submit'"
              v-on:click="validateForm()"
            />
          </div>
        </div>
      </section>
    </Card>
  </div>
</template>

<script>
import AlertModal from "@/components/modals/AlertModal.vue";
import ProcessingModal from "@/components/modals/ProcessingModal.vue";
import CSVImporter from "../../components/comps/CSVImporter.vue";

import {getSaleDate} from "@/helpers/tickets.js";

import dayjs from "dayjs";
import errors from "../Emails/Errors.vue";

export default {
  name: "bulk-upload",
  components: {
    CSVImporter,
    ProcessingModal,
    AlertModal
  },
  head() {
    return {
      title: "Bulk Upload"
    };
  },
  data() {
    return {
      booleen: true,
      breadcrumbs: [
        ["Dashboard", "/dashboard"],
        ["Comps / Will Call", null]
      ],
      pdfTypes: {Letter: 'Letter (8.5"x11")', Stock: "Stock"},
      formData: {
        pdf: "letter",
        firstName: "",
        lastName: "",
        groupName: "",
        email: "",
        phone: "",
        tier: null,
        pickupLocation: "",
        reason: "",
        endDate: ""
      },
      compUploadColumn: [
        {
          name: "first_name",
          property: "firstName",
          required: true,
          example: "John"
        },
        {
          name: "last_name",
          property: "lastName",
          required: true,
          example: "Doe"
        },
        {
          name: "email",
          property: "email",
          required: true,
          example: "example@example.com",
          validate: 'email'
        },
        {name: "phone", property: "phone", example: "+12041233442"},
        {
          name: "tier",
          property: "tier",
          required: true,
          example: "Tier name"
        },
        {
          name: "quantity",
          property: "quantity",
          required: true,
          example: "1"
        },
        {name: "language", property: "language", example: "English"},
        {name: "location", property: "location", example: "address"},
        {name: "reason", property: "reason", example: "Reason"}
      ],
      willcallUploadColumn: [
        {
          name: "first_name",
          property: "firstName",
          required: true,
          example: "John"
        },
        {
          name: "last_name",
          property: "lastName ",
          required: true,
          example: "Doe"
        },
        {
          name: "email",
          property: "email",
          required: true,
          example: "example@example.com",
          validate: 'email'
        },
        {name: "phone", property: "phone", example: "+12041233442"},
        {
          name: "tier",
          property: "tier",
          required: true,
          example: "Tier name"
        },
        {
          name: "quantity",
          property: "quantity",
          required: true,
          example: "1"
        },
        {
          name: "pickup_location",
          property: "pickupLocation",
          required: true,
          example: "Address for pick up tickets"
        },
        {name: "language", property: "language", example: "English"},
        {name: "location", property: "location", example: "address"},
        {name: "reason", property: "reason", example: "Reason"}
      ],
      compGroupUploadColumn: [
        {
          name: "group_name",
          property: "groupName",
          required: true,
          example: "Group 1"
        },
        {
          name: "email",
          property: "email",
          required: true,
          example: "example@example.com",
          validate: 'email'
        },
        {name: "phone", property: "phone", example: "+12041233442"},
        {
          name: "tier",
          property: "tier",
          required: true,
          example: "Tier name"
        },
        {
          name: "quantity",
          property: "quantity",
          required: true,
          example: "1"
        },
        {name: "language", property: "language", example: "English"},
        {name: "location", property: "location", example: "address"},
        {name: "reason", property: "reason", example: "Reason"}
      ],
      willcallGroupUploadColumn: [
        {
          name: "group_name",
          property: "groupName",
          required: true,
          example: "Group 1"
        },
        {
          name: "email",
          property: "email",
          required: true,
          example: "example@example.com",
          validate: 'email'
        },
        {name: "phone", property: "phone", example: "+12041233442"},
        {
          name: "tier",
          property: "tier",
          required: true,
          example: "Tier name"
        },
        {
          name: "quantity",
          property: "quantity",
          required: true,
          example: "1"
        },
        {
          name: "pickup_location",
          property: "pickupLocation",
          required: true,
          example: "Address for pick up tickets"
        },
        {name: "language", property: "language", example: "English"},
        {name: "location", property: "location", example: "address"},
        {name: "reason", property: "reason", example: "Reason"}
      ],
      compsUploaded: [],
      bulkCompsFinal: [],
      fee: 0,
      tixLogo: "",
      logoArray: [],
      logoObj: {},
      searchBar: "",
      searchBar2: "",
      ticketQuantity: [],
      ticketTiers: [],
      compTicketList: [],
      willCallTicketsList: [],
      errorMessages: [],
      errors: [
        {name: "Select Type", error: false},
        {name: "Pickup Location", error: false},
        {name: "First Name", error: false},
        {name: "Last Name", error: false},
        {name: "Email", error: false},
        {name: "Phone", error: false},
        {name: "Ticket Tier", error: false},
        {name: "Ticket Count", error: false},
        {name: "PDF Type", error: false},
        {name: "Comp Reason", error: false},
        {name: "Sale Date", error: false}
      ],
      ticketLogo: "",
      ticketLogoName: "",
      pdf: "Letter",
      tier: null,
      fullName: "",
      firstName: "",
      lastName: "",
      groupName: "",
      email: "",
      phone: "",
      reason: "",
      count: "",
      dateInputID: "",
      saleDateRefArray: [],
      saleDateArr: [],
      compType: "ComplimentaryTickets",
      pickupLocation: "",
      eventUrl: this.$route.params.url,
      oldEvent: {},

      testCounter: 0,
      compButtonTypes: [
        {
          id: "check-comp",
          name: "comp-types",
          label: "Complimentary Tickets",
          checked: true
        },
        {id: "check-will-call", name: "comp-types", label: "Will Call"}
      ],
      pdfButtonTypes: [
        {
          id: "check-letter",
          name: "pdf-types",
          label: 'Letter (8.5"x11")',
          checked: true
        },
        {id: "check-stock", name: "pdf-types", label: "Stock"}
      ],
      imgButtonTypes: [
        {
          id: "check-new-upload",
          name: "upload-types",
          label: "New Upload",
          checked: true
        },
        {
          id: "check-previous-upload",
          name: "upload-types",
          label: "Previous Upload"
        }
      ],
      imgType: "New Upload",

      maxQuantity: 0,
      compsCreated: 0,
      compsToBeCreated: 0,
      pdfData: null,
      pdfFileName: "",
      processing: false,
      eventDateID: null,
      whiteLabelId: "",
      issuedTo: "individual"
    };
  },
  computed: {
    column() {
      if (
        this.compType === "ComplimentaryTickets" &&
        this.issuedTo === "individual"
      ) {
        return this.compUploadColumn;
      } else if (
        this.compType === "ComplimentaryTickets" &&
        this.issuedTo === "group"
      ) {
        return this.compGroupUploadColumn;
      } else if (
        this.compType === "WillCall" &&
        this.issuedTo === "individual"
      ) {
        return this.willcallUploadColumn;
      } else if (this.compType === "WillCall" && this.issuedTo === "group") {
        return this.willcallGroupUploadColumn;
      }
    }
  },
  methods: {
    openReservedMap() {
    },
    onClickCompType(value) {
      this.compType = value;
      // this is undefined
      //this.$refs.pickup.classList.toggle("hidden", true);
    },
    issuingTo(value) {
      this.issuedTo = value;
    },
    downloadExamopleCSV() {
      // Sample data for demonstration
      let columns = [];
      let exampleData = [];
      this.column.forEach(item => {
        columns.push(item.name);
        exampleData.push(item.example);
      });
      const data = [[columns], [exampleData]];

      // Convert data to CSV string
      const csvContent = data.map(row => row.join(",")).join("\n");

      // Create a Blob containing the CSV data
      const blob = new Blob([csvContent], {type: "text/csv;charset=utf-8;"});

      // Create a download link
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "data.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error("Your browser does not support the download attribute.");
      }
    },
    async validateForm() {
      this.processing = true;
      await this.onLoad();
      let hasError = false;
      let limited = false;
      let msg = "<p> Please review this errors: </p>" + "<p> </p>";
      this.compsCreated = 0;
      this.compsToBeCreated = this.compsUploaded.length;

      let user = this.$store.state.user._id;
      // let whiteLabelId = "";

      try {
        let whiteLabelAcc = await this.$axios.post(
          "/white-label/get-white-label-by-user-id",
          {userId: user}
        );

        if (whiteLabelAcc.data.hasDomain) {
          this.whiteLabelId = await whiteLabelAcc.data.whiteLabel._id;
        }
      } catch (error) {
        console.log(error);
      }
      this.compsUploaded.forEach((ticket, index) => {
        ticket.id = index;
        if (ticket) {
          let errs = [];
          this.column.forEach(item => {
            if (!item) return;
            if (item.required && ticket[item.property] == "") {
              errs.push(item.name + ' is required');
              hasError = true;
            }
            if (item.validate && item.validate == 'email') {
              const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
              emailRegex.test(ticket[item.property]);
              if (!emailRegex.test(ticket[item.property])) {
                errs.push('email is invalid');
                hasError = true;
              }
            }
          });
          let elem = null;
          this.oldEvent.tiers.forEach((t) => {
            if (t.name.replace(' ', '') === ticket.tier.replace(' ', '')) {
              elem = t;
              t.saleDates[0].start += Number(ticket.quantity);
              if ((t.saleDates[0].qty - t.saleDates[0].start + t.tixStartNum) < 0) {
                limited = true;
              }
            }
          });
          if (!elem) {
            errs.push('tier name is invalid');
            hasError = true;
          }
          if (errs.length > 0) {
            let i = '';
            errs.forEach((item, key) => {
              if (key + 1 == errs.length) {
                i += item + '.';
              } else {
                i += item + ', ';
              }

            })
            msg += `<p>Line ${index + 2} - ${i}</p>`;
          }
        }
      });
      if (hasError) {
        this.processing = false;
        this.$refs.alert.open({
          title: "Error Uploading",
          msg: msg,
          closable: false,
          onConfirm: () => {
            window.location.reload();
          },
          onClose: () => {
            window.location.reload();
          }
        });
      } else if (limited) {
        let message = `<p>Your CSV contains comps for tiers that do not have enough availability. Please look to edit your ticket tiers and add more availability.</p>`;
        this.processing = false;
        this.$refs.alert.open({
          msg: message,
          closable: false,
          confirm: 'Edit Event',
          onConfirm: () => {
            this.$router.push("/edit-event/" + this.eventUrl);
          },
          onClose: () => {
            window.location.reload();
          }
        });
      } else {
        this.compsUploaded.forEach((ticket, index) => {
          ticket.id = index;
          if (ticket) {
            this.createBulkComps(ticket, index);
          }
        });
        // this.processing = false;
      }
    },

    async createBulkComps(ticket, index) {
      setTimeout(async () => {
        let self = this;
        let arrayOfNumbers = [];
        let date = Date.now();
        let tiers = self.oldEvent.tiers;
        let selectedTier = ticket.tier;

        if (!selectedTier) {
          this.processing = false;
          return;
        }

        const elem = tiers.find(t => t.name === selectedTier);
        if (!elem) {
          this.processing = false;
          this.$refs.alert.open({
            title: "Error Uploading",
            msg:
              "Please check your tier name, we can't find this tier - " +
              selectedTier,
            closable: false
            // confirm: "Continue"
          });
          return;
        }
        let startNumber;
        let endNumber;
        let tierDate;
        let saleDate;

        if (elem.type === "General Tier") {
          saleDate = getSaleDate(elem, elem.saleDates[0].id);
          startNumber = parseInt(saleDate.start);
          endNumber = parseInt(saleDate.start) + parseInt(ticket.quantity);
          saleDate.start += parseInt(ticket.quantity);
          tierDate = dayjs(
            +saleDate.sale_start.$date.$numberLong
          ).toISOString();
        }
        // else if (elem.type === "Date") {
        //   const venueAccessDate = getVenueAccessDate(
        //     elem,
        //     this.tier.venueAccessDateID
        //   );
        //   startNumber = venueAccessDate.sold + elem.tixStartNum;
        //   endNumber = parseInt(startNumber) + parseInt(self.count);
        //   venueAccessDate.sold += parseInt(self.count);
        //   venueAccessDate.available -= parseInt(self.count);
        //   tierDate = dayjs(+venueAccessDate.start).toISOString();
        // } else if (elem.type === "Date and Time") {
        //   const venueAccessTime = getVenueAccessTime(
        //     elem,
        //     this.tier.venueAccessDateID,
        //     this.tier.venueAccessTimeID
        //   );
        //   startNumber = venueAccessTime.sold + elem.tixStartNum;
        //   endNumber = parseInt(startNumber) + parseInt(self.count);
        //   venueAccessTime.sold += parseInt(self.count);
        //   venueAccessTime.available -= parseInt(self.count);
        //   tierDate = dayjs(+venueAccessTime.start).toISOString();
        // }

        for (let t = startNumber; t < endNumber; t++) {
          arrayOfNumbers.push(t);
        }

        let email = ticket.email;
        let userPhoneNumber = ticket.phone;
        let NumOfTickets = parseInt(ticket.quantity);
        let userReason = ticket.reason;
        let tier = elem.name;
        let type = self.pdf.trim();
        let compType = self.compType;
        let pickupLocation = self.pickupLocation;
        let img = self.tixLogo;

        let event_id = self.oldEvent._id;
        if (type.includes("Letter")) {
          type = "Letter";
        }

        if (type === "ComplimentaryTickets") {
          type = "Complimentary Tickets";
        } else {
          type = "Will Call";
        }

        const compTicket = {
          fullName: ticket.firstName + " " + ticket.lastName,
          firstName: ticket.firstName,
          lastName: ticket.lastName,
          language: ticket.language || "",
          location: ticket.location || "",
          email: email,
          event_id: event_id,
          phoneNumber: userPhoneNumber,
          tier_id: elem.id,
          ticketTier: elem.name,
          ticketsCount: NumOfTickets,
          creationDate: date,
          compReason: userReason,
          type: type,
          compType: compType,
          pickupLocation: pickupLocation,
          eventUrl: self.eventUrl,
          tickets: arrayOfNumbers,
          icon: img,
          iconName: self.ticketLogoName,
          ticketType: saleDate.type,
          saleDateID: saleDate.id,
          venueAccessDateID: saleDate.venueAccessDateID || null,
          venueAccessTimeID: saleDate.venueAccessTimeID || null,
          price: 0,
          fee: 0,
          date: this.eventDateID,
          reason: ticket.reason,
          tierDate
        };

        this.bulkCompsFinal.push(compTicket);

        // // send as FormData to properly handle image uploads
        const data = new FormData();
        data.append("data", JSON.stringify(compTicket));

        if (self.imgType === "New Upload") {
          data.append("icon", self.ticketLogo);
        }

        this.$axios
          .post("/comps/comp-tickets", data, {
            headers: {
              "X-Whitelabel-Id": this.whiteLabelId
            }
          })
          .then(response => {
            this.compsCreated++;
            if (this.compsCreated === this.compsToBeCreated) {
              this.processing = false;
              window.location.reload();
            }
          });
      }, index * 500);
    },
    onLoad() {
      let self = this;
      // for multi date events
      // this.eventDateID = dateID;

      this.$axios.get(`/comps/get-comps/${self.eventUrl}`).then(({data}) => {
        this.oldEvent = data.data.event;

        this.fee = parseFloat(data.data.fee.$numberDecimal);

        // this.clearTable();
      });
    }
  },
  async mounted() {
    let user = this.$store.state.user._id;

    try {
      let whiteLabelAcc = await this.$axios.post(
        "/white-label/get-white-label-by-user-id",
        {userId: user}
      );

      if (whiteLabelAcc.data.hasDomain) {
        this.whiteLabelId = await whiteLabelAcc.data.whiteLabel._id;
      }
    } catch (error) {
      console.log(error);
    }

    // Used to be on onload of header
    this.onLoad();
  }
};
</script>

<style lang="less">
.redirect-button {
  text-align: -webkit-right;

  .formulate-input[data-classification="button"] button {
    background: var(--secondary-green);
  }
}

.formulate-input {
  &[data-classification="submit"] button {
    margin-left: initial;
  }
}
</style>
<style lang="less" scoped>
.csv-importer {
  margin-bottom: 16px;
}

.comps-buttons-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  #submit-button {
    margin-left: 16px;
    width: 132px;
  }
}

.splitter {
  display: flex;
  justify-content: center !important;
  position: relative;
  font-weight: bold;
  padding: 10px;

  &::before {
    content: " ";
    display: block;
    position: absolute;
    top: 50%;
    right: 58%;
    width: 25%;
    border-bottom: 2px solid @colors[primary-green];
  }

  &::after {
    content: " ";
    display: block;
    position: absolute;
    top: 50%;
    left: 58%;
    width: 25%;
    border-bottom: 2px solid @colors[primary-green];
  }
}

.imports-items {
  display: flex;
  padding: 16px;
  border: 1px solid var(--dashboard-border);
  border-radius: 5px;
  box-shadow: var(--table-shadow);
}

.table {
  width: 100%;
  border-collapse: collapse;

  tr {
    height: 30px;
    border-bottom: 1px solid var(--dashboard-border);

    th {
      display: flex;
      text-align: start;
    }
  }

  .for-btn {
    width: 120px;
  }

  .reserved-btn {
    margin: 5px 0px;

    .btn {
      padding: 5px 5px;
      z-index: 1;
      border: 0px solid;
      border-radius: 5px;
      font-size: 1.2em;
      display: flex;
      display: -webkit-flex;
      -webkit-justify-content: center;
      -webkit-align-items: center;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      cursor: pointer;
      outline: none;
      transition: 0.3s linear;
      color: white;
      background: var(--primary);
    }
  }
}
</style>
