<template>
  <div class="errors">
    <update-contact-modal ref="updateContactModal" @submit="onErrorUpdated" />
    <h2>Errors</h2>
    <div v-if="errors === null" class="error-list">
      <loader />
    </div>
    <div v-else-if="errors.length === 0" class="error-list row">
      <div class="col half center flex">You have no errors.</div>
    </div>
    <div v-else class="error-list">
      <div v-for="error in errors" class="row error" :key="error.id">
        <div class="left">
          <div class="info">
            <span class="email">{{ error.contact.email }}</span>
            <span class="name">
              {{ error.contact.first_name }} {{ error.contact.last_name }}
            </span>
            <br />
            <span class="reason">
              Bounced at {{ formatDate(error.timestamp) }}
            </span>
          </div>
          <div class="lists">
            <div class="title">Added To Lists</div>
            <span v-for="list in error.contact.lists" :key="list.id">
              {{ list.name }}
            </span>
          </div>
        </div>
        <div class="right">
          <diyobo-button
            type="primary"
            txt="Update"
            @click="onClickUpdate(error)"
          />
          <diyobo-button
            type="secondary"
            txt="Delete"
            @click="onClickDelete(error)"
          />
        </div>
      </div>
    </div>
    <div class="button-wrapper">
      <diyobo-button
        v-if="showLoadMore"
        type="primary"
        txt="Load More"
        @click="loadMore"
      />
    </div>
  </div>
</template>

<script>
import DiyoboButton from "@/components/DiyoboButton.vue";
import Loader from "@/components/Loader.vue";
import ModalContent from "@/helpers/modals";
import UpdateContactModal from "../../components/modals/UpdateContactModal";
import dateformat from "dateformat";

export default {
  name: "email-errors",
  components: {
    UpdateContactModal,
    Loader,
    DiyoboButton
  },
  props: {
    url: String
  },
  head() {
    return {
      title: "Errors"
    };
  },
  data() {
    return {
      errors: null,
      loading: false,
      offset: 0,
      hasMore: false
    };
  },
  computed: {
    showLoadMore() {
      return this.errors && !this.loading && this.hasMore;
    }
  },
  methods: {
    onClickUpdate(error) {
      this.$refs.updateContactModal.open({ contact: error.contact, error });
    },
    onClickDelete(error) {
      const content = ModalContent.deleteContact;
      this.$bus.$emit("alert", {
        msg: content.msg.replace("{email}", error.contact.email),
        confirm: content.confirm,
        closable: true,
        onConfirm: () => {
          this.$axios
            .post("/emails/contacts/delete", {
              errorID: error.id,
              contactID: error.contact.id
            })
            .then(() => {
              this.onErrorUpdated(error);
            });
        }
      });
    },
    onErrorUpdated(error) {
      const index = this.errors.indexOf(error);

      if (index > -1) {
        this.errors.splice(index, 1);
        this.offset--;
      }
    },
    loadMore() {
      if (!this.loading) {
        this.loading = true;

        this.$axios
          .get("/emails/errors/" + this.url, {
            params: {
              offset: this.offset
            }
          })
          .then(response => {
            const data = response.data;
            const errors = data.errors;

            this.errors.push(...errors);

            this.offset += errors.length;
            this.hasMore = errors.length === 20;
            this.loading = false;
          });
      }
    },
    formatDate(date) {
      return dateformat(Number(date), "ddd, mmm dS, yyyy");
    }
  },
  created() {
    this.$axios.get("/emails/errors/" + this.url).then(response => {
      const data = response.data;

      this.$emit("set-title", data.event);

      this.errors = data.errors || [];
      this.offset = data.errors.length;
      this.hasMore = data.errors.length === 20;
    });
  }
};
</script>

<style lang="less" scoped>
.errors {
  .error-list {
    display: flex;
    width: 100%;
    flex-direction: column;

    .error {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 16px;
      padding: 10px 20px;
      box-sizing: border-box;
      border: 1px solid var(--input-border);
      border-radius: 8px;

      .left {
        display: flex;
        flex: 1;

        .info {
          display: flex;
          flex-direction: column;
          margin-right: 32px;

          .email {
            font-weight: bold;
            font-size: 20px;
          }
        }

        .lists {
          display: flex;
          flex-direction: column;

          .title {
            font-weight: bold;
          }
        }
      }

      .right {
        display: flex;
        justify-content: flex-end;

        button {
          margin: 0 0 auto 16px;
        }
      }

      @media screen and (max-width: 800px) {
        .left {
          flex-direction: column;

          .info,
          .lists {
            margin-bottom: 16px;
          }
        }

        .right {
          button:first-of-type {
            margin-left: 0;
          }
        }
      }
    }
  }

  .button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
}
</style>
